<template>
  <div>
    <!--<img class="w100" style="display: block;" src="@/assets/images/os/bg.png">-->
    <el-image v-if="banner" class="w100" style="display: block;" :src="banner"></el-image>

    <div class="w100 technical-tab">
      <ul>
        <li :class="{'active': type == item.value}" v-for="(item, i) in types" :underline="false" @click="changeType(item.value)">{{item[lang + 'name']}}</li>
      </ul>
    </div>

    <div class="technical-warp mt20">
      <el-row :gutter="20" type="flex" justify="start" class="flex-wrap mt20" v-loading="loading">
        <el-col :xs="12"
                :sm="12"
                :md="12"
                :lg="item.type == 'enterpriseImages' || item.type == 'activityImages' || item.type == 'mediaVideo' ? 12 : 6"
                :xl="item.type == 'enterpriseImages' || item.type == 'activityImages' || item.type == 'mediaVideo' ? 12 : 6"
                v-for="(item, i) in list"
                :key="item.id">

          <div class="wrap_content_item_box">
            <div v-if="item.type == 'enterpriseImages' || item.type == 'activityImages'">
              <el-image style="width: 100%;" :src="item.thumbnail" :preview-src-list="item.attrs && item.attrs.length > 0 ? item.attrs : []"/>
              <div class="wrap_content_item_box_card">
                <h3 class="title">{{item[lang + 'title']}}</h3>
              </div>
            </div>

            <div v-else-if="item.type == 'mediaVideo'">
              <!--<el-image style="width: 100%; height: 180px;" :src="item.thumbnail"/>-->
              <video-player
                  v-if="item['en_content'] != 1"
                  ref="videoPlayer"
                  :options="playerOptions[i]"
                  :playsinline="true">
              </video-player>

              <iframe v-else width="100%" height="330" frameborder="0" :src="item['content']" referrerpolicy="unsafe-url" allowfullscreen></iframe>
              <div class="wrap_content_item_box_card">
                <h3 class="title">{{item[lang + 'title']}}</h3>
              </div>
            </div>

            <div v-else  @click="goto(item.id)">
              <el-image style="width: 100%; height: 180px;" fit="cover" :src="item.thumbnail"/>
              <div class="wrap_content_item_box_card">
                <h3 class="title">{{item[lang + 'title']}}</h3>
                <p class="desc">{{item[lang + 'desc']}}</p>
              </div>

              <div class="entry-content">
                <span>{{item.create_time}}</span>
              </div>
            </div>

          </div>
        </el-col>
      </el-row>

      <el-pagination
          class="mb20 line"
          background
          :hide-on-single-page="onePage"
          :total="total"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          @size-change="get"
          @current-change="get"
          layout="prev, pager, next">
      </el-pagination>
    </div>
  </div>
</template>


<script>
import config from '@/config';
import {getPosts, getOption} from "@/api/os/admin";
import 'video.js/dist/video-js.css'
import { videoPlayer } from "vue-video-player"

export default {
  components: {
    videoPlayer
  },
  data() {
    return {
      optionName: 'page-banner',
      banner: '',
      type: config.default.mediaType[0].value,
      types: config.default.mediaType,
      currentPage: 0,
      pageSize: config.default.pageLimit,
      // pageSizes: config.default.pageSizes,
      onePage: false,
      total: 0,
      loading: false,
      list: [],

      playerOptions: []
    }
  },
  created() {
    this.getBanner()
    if(this.$route.query.type) {
      this.type = this.$route.query.type
    }
    this.get()
  },
  computed: {
    // add
    lang() {
      if(this.$i18n.locale == 'zh-CN') {
        return ''
      }
      return 'en_';
    }
  },
  methods: {
    getBanner() {
      getOption({
        name: this.optionName,
      }).then(res => {
        if(res.data.data) {
          this.banner = res.data.data.media
        }
      })
    },
    get() {
      this.loading = true
      getPosts({
        page: this.currentPage,
        pageSize: this.pageSize,
        type: this.type
      }).then(res => {
        this.loading = false
        this.list = res.data.data.list
        this.total = res.data.data.total

        if(this.type == 'mediaVideo') {
          let options = [];
          for (let i of this.list) {
            let arrStr = {
              playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
              autoplay: false, //如果true,浏览器准备好时开始回放。
              muted: false, // 默认情况下将会消除任何音频。
              loop: false, // 导致视频一结束就重新开始。
              preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
              language: "zh-CN",
              aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
              fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
              notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
              sources: [
                {
                  type: "", //这里的种类支持很多种：基本视频格式、直播、流媒体等
                  src: i.content, //url地址 "../../static/vedio/test1.mp4"
                },
              ],
              poster: i.thumbnail, //你的封面地址 "../../static/vedio/test.jpg"
              controlBar: {
                timeDivider: true,
                durationDisplay: true,
                remainingTimeDisplay: false,
                fullscreenToggle: true, //全屏按钮
              },
            };
            options.push(arrStr);
          }

          this.playerOptions = options
        }
      })
    },
    changeType(type) {
      this.type = type
      this.get()
    },
    goto(id) {
      this.$router.push({
        name: 'post',
        params: {
          id: id,
          type: this.type
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.active{
  color: #00923F;
  border-bottom: 2px solid #00923F;
}
.technical-tab {
  background: #eeeeee;
  ul{
    displey:-webkit-flex;
    display: flex;
    -webkit-flex-flow:row nowrap; //设置排列方式为横向排列，并且超出元素不换行
    flex-flow:row nowrap;
    overflow-x: auto;
    white-space:nowrap;
    max-width: 1200px;
    margin: auto;
    padding: 0 10px;
    li{
      cursor: pointer;
      display: inline-block;
      height: 50px;
      line-height: 50px;
      margin-right: 60px;
      font-size: 16px;
    }
  }
}
.technical-warp{
  max-width: 1200px;
  margin: auto;
}
.wrap_content_item_box{
  border: 1px solid #e6ebf5;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.05);
  margin-bottom: 20px;
  cursor: pointer;
  .wrap_content_item_box_card{
    .title{
      height: 52px;
      margin: 10px;
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .desc{
      font-size: .8rem;
      height: 52px;
      margin: 10px;
      color: #A2A2A2;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .entry-content{
    margin: 10px;
    color: #B3B3B3;
  }
}

/* maxWidth <=768 */
@media screen and (max-width: 768px) {
  .wrap_content_item_box {
    .el-image {
      height: 130px !important;
    }
    .wrap_content_item_box_card{
      .title{
        height: 36px;
        font-size: 14px;
        line-height: 18px;
      }
      .desc{
        height: 36px;
        font-size: 12px;
      }
    }
  }
}
</style>